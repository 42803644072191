
	import { defineComponent, ref, onMounted } from "vue";
	import { ErrorMessage, Field, Form } from "vee-validate";
	import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
	import Swal from "sweetalert2/dist/sweetalert2.min.js";

	import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";

	export default defineComponent({
		name: "Sales",
		components: {
			Form,
			ElCurrencyInputNoSymbol,
		},
		async mounted() {
			this.init();
			this.initMonths();
		},
		data() {
			const months = [
				"Jan",
				"Feb",
				"Mac",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			];

			const statements = {
				salesActivities: {
					label: "Sales Activities",
					child: {
						salesGoodsTrading: {
							label: "Sales of Goods/Trading",
							value: [],
							total: 0,
						},
						servicesConsulting: {
							label: "Services/Consulting",
							value: [],
							total: 0,
						},
						others: {
							label: "Others",
							value: [],
							total: 0,
						},
					},
				},
				totalSales: {
					label: "Total",
					value: [],
					total: 0,
				},
				totalMonths: {
					label: "Total in Months",
					value: [],
					total: 0,
				},
			};

			return {
				months,
				statements,
			};
		},
		setup() {
			return {};
		},
		methods: {
			init() {
				setCurrentPageBreadcrumbs("Sales", [
					"Financial Statements",
					"Income Statement",
				]);
			},

			onSubmit(values) {
				console.log(values);
				Swal.fire({
					title: "Congratulation!",
					text: "Calculation has been saved!",
					icon: "success",
					showCancelButton: false,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "OK",
				});
			},

			initMonths() {
				for (let index = 0; index < this.months.length; index++) {
					this.statements.totalSales.value.push(0);
				}

				for (let key of Object.keys(this.statements.salesActivities.child)) {
					let salesActivitiesChild = this.statements.salesActivities.child;
					for (let index = 0; index < this.months.length; index++) {
						salesActivitiesChild[key].value.push(0);
					}
				}
			},

			calculateTotal() {
				for (let i = 0; i < this.statements.totalSales.value.length; i++) {
					this.statements.totalSales.value[i] = 0;
				}

				for (let keyChild of Object.keys(this.statements.salesActivities.child)) {
					let salesActivitiesChild =
						this.statements.salesActivities.child[keyChild];
					let stateChildValue = salesActivitiesChild.value;

					salesActivitiesChild.total = stateChildValue.reduce(
						(sum, item) => sum + item
					);

					for (let i = 0; i < this.statements.totalSales.value.length; i++) {
						this.statements.totalSales.value[i] += stateChildValue[i];
					}
				}
			},
		},
	});
